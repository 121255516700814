<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>  
            <li v-if="getUrlParameter('IdSecretariaCurso')" class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/cursos'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/cursos')"
              >Cursos</a>
            </li>          
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Módulos de avaliações</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">          
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Módulos de
                  <span>avaliações</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de módulos
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="fastAvaliacaoModeloEditar.id_avaliacao_modelo = 0; showModal('modalCriarAvaliacaoModelo')"
                          >
                            <small>+ Cadastrar módulo de avaliação</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Filtro por nome da avaliação"
                          >
                        </div>                          
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th class="text-center">
                                  <small class="font-weight-bold">Módulo</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Tem recuperação</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastAvaliacaoModelo.loading">
                              <tr>
                                <td
                                  colspan="3"
                                  class="text-center"
                                >
                                 <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Carregando dados
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastAvaliacaoModelo.paginacao.length">
                              <tr
                                v-for="(e, index) in fastAvaliacaoModelo.paginacao"
                                :key="index"
                              >
                                <td class="align-middle text-center">
                                  <small>{{ e.nome_curso + ' - ' + e.sigla_programa }}</small>
                                </td>
                                <td class="align-middle text-center">
                                  <span v-if="e.tem_recuperacao">
                                    <b-badge variant="success">Sim</b-badge>
                                  </span>
                                  <span v-else>
                                    <b-badge variant="danger">Não</b-badge>
                                  </span>
                                </td>
                                <td class="text-center align-middle">     
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacao-modelo/' + e.id_avaliacao_modelo)"
                                  >
                                    <small>UCs</small>
                                  </button>                             
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarAvaliacaoModelo(e)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirAvaliacaoModelo(e)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="3"
                                  class="text-center"
                                >
                                  Nenhum modelo de avaliação configurado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="fastAvaliacaoModelo.filtro"
                            @changePage="
                              fastAvaliacaoModelo.paginacao = $event
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">              
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/cursos')"
                >
                  <b-icon-arrow-return-left />
                  Voltar 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarAvaliacaoModelo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo modelo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAvaliacaoModelo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Curso - Módulo</label>
              <button class="btn btn-sm btn-secondary ml-2 pt-0 pb-0" @click="showModal('modalSecretariaCursos', 'modalCriarAvaliacaoModelo')">
                <small> <b-icon-pencil-square />  Alterar</small>
              </button>
              <input                
                type="text"
                class="form-control"
                readonly
                :value="fastAvaliacaoModeloNovo.id_secretaria_curso_programa ? fastAvaliacaoModeloNovo.nome_curso + ' - ' + fastAvaliacaoModeloNovo.sigla_programa : 'Nenhum definido'"
              >
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Tem recuperação</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastAvaliacaoModeloNovo.tem_recuperacao"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>                    
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarAvaliacaoModelo()"
                >
                  Criar modelo de avaliação
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarAvaliacaoModelo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar modelo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarAvaliacaoModelo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Curso - Módulo</label>
              <button class="btn btn-sm btn-secondary ml-2 pt-0 pb-0" @click="showModal('modalSecretariaCursos', 'modalEditarAvaliacaoModelo')">
                <small> <b-icon-pencil-square />  Alterar</small>
              </button>
              <input                
                type="text"
                class="form-control"
                readonly
                :value="fastAvaliacaoModeloEditar.id_secretaria_curso_programa ? fastAvaliacaoModeloEditar.nome_curso + ' - ' + fastAvaliacaoModeloEditar.sigla_programa : 'Nenhum definido'"
              >
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Tem recuperação</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastAvaliacaoModeloEditar.tem_recuperacao"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>                    
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarAvaliacaoModelo()"
                >
                  Atualizar modelo de avaliação
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirAvaliacaoModelo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir modelo de avaliação?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirAvaliacaoModelo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirAvaliacaoModelo()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Lista de cursos da secretaria</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Nome do curso</small>
                    </th>
                    <th>
                      <small class="font-weight-bold">Nível</small>                                  
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Carga horária</small>
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Preço</small>
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastSecretariaCursos.loading">
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                      <b-icon
                        icon="gear-fill"
                        animation="spin"
                      /> Carregando dados
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastSecretariaCursos.filtro.length">
                  <tr
                    v-for="curso in fastSecretariaCursos.paginacao"
                    :key="curso.id_secretaria_curso"
                  >
                    <td class="align-middle">
                      <small>{{ curso.nome_curso }}</small>  
                    </td>  
                    <td class="align-middle">
                      <small>{{ retornaCursoNivel(curso.nivel) }}</small> 
                    </td>  
                    <td class="align-middle text-center">
                      <span v-if="curso.carga_horaria">
                        <small>{{ curso.carga_horaria }}h</small>                                    
                      </span>                                  
                    </td> 
                    <td class="align-middle text-center">
                      <small v-if="curso.preco">R$ {{ formataPreco(curso.preco) }}</small>
                      <small
                        v-else
                        class="text-danger"
                      >Indefinido</small>                           
                    </td> 
                    <td class="align-middle text-center">
                      <span
                        v-if="curso.publicado"
                        class="btn btn-sm btn-success pt-0 pb-0"
                      >
                        <small>Sim</small>
                      </span>
                      <span
                        v-else
                        class="btn btn-sm btn-danger pt-0 pb-0"
                      >
                        <small>Não</small>
                      </span>
                    </td>                          
                    <td class="text-center align-middle">                               
                      <button
                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                        @click="exibeModalModulosCurso(curso)"
                      >
                        <small>Exibir módulos</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                      Nenhum curso cadastrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="10"
                :items="fastSecretariaCursos.filtro"
                @changePage="fastSecretariaCursos.paginacao = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCursoProgramas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Lista de módulos</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCursoProgramas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Sequência</small>
                    </th>
                    <th>
                      <small class="font-weight-bold">Módulo</small>
                    </th> 
                    <th>
                      <small class="font-weight-bold">Créditos</small>
                    </th> 
                    <th>
                      <small class="font-weight-bold">Pré-requisito</small>
                    </th>
                    <th>
                      <small class="font-weight-bold">Preço</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastSecretariaCursoProgramas.loading">
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                      <b-icon
                        icon="gear-fill"
                        animation="spin"
                      /> Carregando dados
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastSecretariaCursoProgramas.total.length">
                  <tr
                    v-for="(programa, index) in fastSecretariaCursoProgramas.total"
                    :key="index"
                  >
                    <td class="align-middle">
                      <input
                        v-model="programa.sequencia"
                        style="width: 70px"
                        type="number"
                        min="1"
                        class="form-control form-control-sm"
                        readonly
                        @change="corrigeSequenciaPrograma(programa)"
                      >
                    </td>
                    <td class="align-middle">
                      <small>{{ programa.sigla_programa }}</small>
                    </td>  
                    <td class="align-middle">
                      <small v-if="programa.creditos">{{ programa.creditos }}</small>
                      <small
                        v-else
                        class="text-danger"
                      >Nenhum</small>
                    </td>  
                    <td class="align-middle">
                      <div
                        v-for="programa2 in fastSecretariaCursoProgramas.total"
                        :key="programa2.id_secretaria_curso_programa"
                      >
                        <small v-if="programa2.id_secretaria_curso_programa == programa.id_pai">{{ programa2.sigla_programa }}</small>
                      </div>
                    </td> 
                    <td class="align-middle">
                      <small v-if="programa.preco_modulo">R$ {{ formataPreco(programa.preco_modulo) }}</small>
                      <small
                        v-else
                        class="text-danger"
                      >Indefinido</small>
                    </td>                             
                    <td class="text-center align-middle">
                      <button
                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                        @click="selecionaModuloCurso(programa)"
                      >
                        <small> <b-icon-check2-circle /> Selecionar</small>
                      </button>     
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                      Nenhum módulo cadastrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaAvaliacoesModelos",
  components: {Pagination},
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Modelos
      fastAvaliacaoModelo: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },      
      fastAvaliacaoModeloNovo: {
        id_avaliacao_modelo: 0,
        id_secretaria_curso_programa: 0,
        tem_recuperacao: false,
        id_email_avaliacao_liberada: 0,
        id_email_avaliacao_recuperacao: 0,
        id_email_avaliacao_aprovador: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_curso: "",
        sigla_programa: ""
      },
      fastAvaliacaoModeloEditar: {
        id_avaliacao_modelo: 0,
        id_secretaria_curso_programa: 0,
        tem_recuperacao: false,
        id_email_avaliacao_liberada: 0,
        id_email_avaliacao_recuperacao: 0,
        id_email_avaliacao_aprovador: 0,
        id_plataforma: 0,
        nome_curso: "",
        sigla_programa: ""
      },
      fastSecretariaCursos: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastSecretariaCursoProgramas: {
        total: [],
        loading: true
      }
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Recupera modelos
            this.getAvaliacoesModelo(this.$route.params.id_plataforma);
            // Recupera cursos da secretaria
            this.getSecretariaCursos(this.$route.params.id_plataforma);
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
            
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getAvaliacoesModelo(id_plataforma){
      let id_secretaria_curso = 0
      if (this.getUrlParameter('IdSecretariaCurso')) {
        id_secretaria_curso = parseInt(this.getUrlParameter('IdSecretariaCurso'))
      }

      this.promiseGetFastApi("api/fast_secretaria_avaliacao_modelo/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso_programa=0&id_secretaria_curso=" + id_secretaria_curso).then(obj => {
        if (obj.length) {          
          this.fastAvaliacaoModelo.total = obj
          this.fastAvaliacaoModelo.filtro = obj
        }
        else {
          this.fastAvaliacaoModelo.total = []
          this.fastAvaliacaoModelo.filtro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastAvaliacaoModelo.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar modelos de avaliação", "error");
        this.$store.state.fastCarregando = false;
        this.fastAvaliacaoModelo.loading = false
      })
    },
    getSecretariaCursos(id_plataforma){
      this.fastSecretariaCursosLoading = true
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(json => {
        let obj = Array.from(json);        
        if (obj.length) {  
          if (this.getUrlParameter('IdSecretariaCurso')) {
            let id_secretaria_curso = parseInt(this.getUrlParameter('IdSecretariaCurso'))
            obj = obj.filter(e => e.id_secretaria_curso == id_secretaria_curso)
          }

          this.fastSecretariaCursos.total = obj;
          this.fastSecretariaCursos.filtro = obj;
        } else {
          this.fastSecretariaCursos.total = [];
          this.fastSecretariaCursos.filtro = obj;
        }
        
        console.log("this.fastSecretariaCursos", this.fastSecretariaCursos)

        this.$store.state.fastCarregando = false
        this.fastSecretariaCursos.loading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCursos.loading = false
      })
    },
    async criarAvaliacaoModelo() {
        
        let erros = []
        
        if (!this.fastAvaliacaoModeloNovo.id_secretaria_curso_programa) erros.push("Campo obrigatório: módulo do curso")
        if (!this.fastAvaliacaoModeloNovo.id_plataforma) erros.push("Campo obrigatório: id_plataforma")

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promisePostFastApi(this.fastAvaliacaoModeloNovo, "api/fast_secretaria_avaliacao_modelo/insere")
            .then((res) => {
                this.exibeToasty("Modelo criado com sucesso", "success");
                this.hideModal("modalCriarAvaliacaoModelo");
                //this.fastAvaliacaoModelo.total = res
                //this.fastAvaliacaoModelo.filtro = res
                this.getAvaliacoesModelo(this.$route.params.id_plataforma)
                this.$store.state.fastCarregando = false;
            }).catch((e) => {
                this.exibeToasty("Erro ao criar modelo", "error");
                this.$store.state.fastCarregando = false;
            });
        }      
    },
    exibeModalEditarAvaliacaoModelo(obj) {
      this.fastAvaliacaoModeloEditar = obj;
      this.showModal("modalEditarAvaliacaoModelo");
    },
    async editarAvaliacaoModelo() {
      console.log("this.AvaliacaoModeloEditar", this.fastAvaliacaoModeloEditar)
      let erros = []
        
        if (!this.fastAvaliacaoModeloEditar.id_secretaria_curso_programa) erros.push("Campo obrigatório: módulo do curso")
        if (!this.fastAvaliacaoModeloEditar.id_plataforma) erros.push("Campo obrigatório: id_plataforma")
        if (!this.fastAvaliacaoModeloEditar.id_email_avaliacao_liberada) this.fastAvaliacaoModeloEditar.id_email_avaliacao_liberada = 0
        if (!this.fastAvaliacaoModeloEditar.id_email_avaliacao_recuperacao) this.fastAvaliacaoModeloEditar.id_email_avaliacao_recuperacao = 0
        if (!this.fastAvaliacaoModeloEditar.id_email_avaliacao_aprovador) this.fastAvaliacaoModeloEditar.id_email_avaliacao_aprovador = 0


        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promisePostFastApi(this.fastAvaliacaoModeloEditar, "api/fast_secretaria_avaliacao_modelo/atualiza").then(e => {
                this.exibeToasty("Salvo com sucesso", "success");                
                this.hideModal("modalEditarAvaliacaoModelo");
                this.$store.state.fastCarregando = false;
            }).catch(e => {
                this.exibeToasty("Erro ao salvar alterações", "error");
                this.$store.state.fastCarregando = false;
            });
        }        
    },
    exibeModalExcluirAvaliacaoModelo(obj) {
      this.fastAvaliacaoModeloEditar = obj;
      this.showModal("modalExcluirAvaliacaoModelo");
    },
    async excluirAvaliacaoModelo(){
        this.$store.state.fastCarregando = true;
        this.promisePostFastApi(this.fastAvaliacaoModeloEditar, "api/fast_secretaria_avaliacao_modelo/exclui").then(e => {
            this.exibeToasty("Modelo excluído com sucesso", "success");        
            this.getAvaliacoesModelo(this.$route.params.id_plataforma);   
            this.hideModal("modalExcluirAvaliacaoModelo");
        }).catch(e => {
            this.exibeToasty("Erro ao excluir", "error");
            this.$store.state.fastCarregando = false;
        });
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    exibeModalModulosCurso(curso) {
      if (this.fastAvaliacaoModeloEditar.id_avaliacao_modelo) {
        this.fastAvaliacaoModeloEditar.nome_curso = curso.nome_curso
      } else {
        this.fastAvaliacaoModeloNovo.nome_curso = curso.nome_curso
      }

      this.showModal('modalSecretariaCursoProgramas', 'modalSecretariaCursos')
      this.fastSecretariaCursoProgramas.loading = true
      this.promiseGetFastApi("api/fast_secretaria_curso_programa/lista", "id_plataforma=" + curso.id_plataforma + "&id_secretaria_curso=" + curso.id_secretaria_curso)
      .then(json => {
        let obj = Array.from(json);        
        if (obj.length) {  
          this.fastSecretariaCursoProgramas.total = obj;
        } else {
          this.fastSecretariaCursoProgramas.total = [];
        }        
        this.$store.state.fastCarregando = false
        this.fastSecretariaCursoProgramas.loading = false
        
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCursoProgramas.loading = false
      })

    },
    corrigeSequenciaPrograma(curso) {
      this.fastSecretariaCursoProgramas.total.sort(this.reornedarArrayObjetos("sequencia"));
    },
    selecionaModuloCurso(programa) {
      if (this.fastAvaliacaoModeloEditar.id_avaliacao_modelo) {
        this.fastAvaliacaoModeloEditar.id_secretaria_curso_programa = programa.id_secretaria_curso_programa
        this.fastAvaliacaoModeloEditar.sigla_programa = programa.sigla_programa
        this.showModal('modalEditarAvaliacaoModelo', 'modalSecretariaCursoProgramas')
      } else {
        this.fastAvaliacaoModeloNovo.id_secretaria_curso_programa = programa.id_secretaria_curso_programa
        this.fastAvaliacaoModeloNovo.sigla_programa = programa.sigla_programa
        this.showModal('modalCriarAvaliacaoModelo', 'modalSecretariaCursoProgramas')
      }


    },
    
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
